/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../src/assets/sass/variables.scss";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/core/main.min.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/styles/flags/flags.css";

@font-face {
    font-family: "Nunito";
    src: url("./assets/fonts/Nunito-Regular.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}
